<template>
  <div class="box"  :class="{active: showActive}" @click="anyesc">

      <img class="logo1" src="../../assets/day1_slices/logo@2x.png" alt="" />
      <div class="name"></div>
      <div class="pc-left-white"></div>
      <img
        class="iphone-left"
        src="../../assets/day1_slices/组 7@2x.png"
        alt=""
      />
      <img
        class="iphone-right"
        src="../../assets/day1_slices/组 8@2x.png"
        alt=""
      />
      <img
        class="small-right"
        src="../../assets/day1_slices/球5@2x.png"
        alt=""
      />
      <img class="big-right" src="../../assets/day1_slices/球2@2x.png" alt="" />
      <div class="blue-left"></div>
      <div class="out-install">
        <div class="install" @click.stop="install">
          <span class="bag">安卓下载</span>
        </div>
        <div class="scan-code">
          <span class="bag">扫码下载</span>
        </div>
      </div>

      <div class="atomic-link">
        <p class="title">原子链钱包</p>
        <p class="desc">链接ATOSHI生态</p>
      </div>
      <div class="thread2">
        <div class="end-blue-right"></div>
      </div>
      <!-- 中英文切换 -->
      <div class="chinese-english">
                <div class="chinese">
          简体中文
        </div>
             <div class="english" @click="toEn">
          EN
        </div>

      </div>
      <!-- 移动端中英文切换 -->
      <div class="middle-chinese-english" @click="toEn">
        Switch to English
      </div>
  </div>
</template>

<script>
import { myMixins } from "../mixins";
export default {
  name: "Home",
  mixins: [myMixins],
};
</script>
<style  lang="less" scoped>
@import url('../public.less');
</style>